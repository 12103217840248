import {createRoot} from "react-dom/client";
import React from "react"
import BGPrivacyPolicyApproval from "./components/BGPrivacyPolicyApproval";

document.addEventListener("DOMContentLoaded", () => {
    const mountPoint = document.getElementById('bg-privacy-policy')
    if (mountPoint) {
        const root = createRoot(mountPoint)
        root.render(<BGPrivacyPolicyApproval submitUrl={mountPoint.getAttribute('data-submit-url')} approvalText={mountPoint.getAttribute('data-approve-text')} submitText={mountPoint.getAttribute('data-submit-text')} />)
    }
});
