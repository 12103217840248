import React, {useState} from "react"
import axios from "axios"
import BGAlert from "./BGAlert";
import BGSpinnerOverlay from "./calendar/BGSpinnerOverlay";

export default function BGPrivacyPolicyApproval({submitUrl = '', approvalText = 'I hereby agree', submitText = 'Submit'}) {
    const [approved, toggleApproved] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)

    const [alertType, setAlertType] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    function handleApproveChange() {
        if (approved) {
            toggleApproved(0)
        } else {
            toggleApproved(1)
        }
    }

    function handleSubmitClick() {
        setIsLoading(true)
        axios.post(submitUrl).then((resp) => {
            setAlertType("success")
            setAlertMessage(resp.data.userMessage)
        }).catch((err) => {
            setAlertType("error")
            setAlertMessage("An error occurred while trying to complete the request")
            if (err.response.data.error) {
                console.log(err.response.error)
            }
            if (err.response.data.userError) {
                setAlertMessage(err.response.data.userError)
            }
        }).finally(() => {
            setIsSubmitted(true)
            setIsLoading(false)
        })
    }

    return (
        <div className={'position-relative'}>
            <BGSpinnerOverlay isActive={isLoading}/>
            {isSubmitted ? (
                <BGAlert type={alertType} message={alertMessage}/>
            ) : (
                <div className={'d-flex align-items-center'}>
                    <div className={'flex-grow-1'}>
                        <div className={"form-check form-switch mb-0"}>
                            <input
                                className={"form-check-input"}
                                type={"checkbox"}
                                id={"approvalSwitch"}
                                onChange={handleApproveChange}
                                value={approved}
                            />
                            <label className="form-check-label" htmlFor="approvalSwitch">{approvalText}</label>
                        </div>
                    </div>
                    <div>
                        <button
                            disabled={!approved}
                            type={"button"}
                            className={"btn btn-primary text-white"}
                            onClick={handleSubmitClick}
                        >{submitText}</button>
                    </div>
                </div>
            )}
        </div>
    )
}
