import {createRoot} from "react-dom/client";

import 'bootstrap/dist/css/bootstrap.min.css';

import '../styles/login.scss';

import './privacy_policy.jsx'

import React from "react";
import LoginPage from "./views/login.jsx";

document.addEventListener("DOMContentLoaded", () => {
    const mountPoint = document.getElementById('login')

    const root = createRoot(mountPoint)
    root.render(<LoginPage/>)
})
