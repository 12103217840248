import React, {useEffect, useState} from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence, browserSessionPersistence } from 'firebase/auth';

const base64Config = import.meta.env.VITE_FIREBASE_CONFIG_BASE64;
const firebaseConfig = JSON.parse(atob(base64Config));
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const setCookie = (name, value, days) => {
        const isSecure = window.location.protocol === 'https:';
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }

        let domain = "";
        const host = window.location.hostname;

        // Check if the current host is part of the balancegolf.com domain
        if (host.endsWith(".balancegolf.com")) {
            domain = "; domain=.balancegolf.com";
        }

        // Set cookie for the correct domain (use .balancegolf.com in production or nothing for localhost)
        document.cookie = name + "=" + (value || "") + expires + "; path=/" + domain + (isSecure ? "; Secure" : "");
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(''); // Clear any previous errors

        const rememberMe = document.getElementById("remember-me").checked;

        try {
            await setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence);

            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Get the ID token
            const idToken = await user.getIdToken();
            const refreshToken = user.refreshToken;

            setCookie('firebase_id_token', idToken, 1);
            setCookie('firebase_refresh_token', refreshToken, 14);

            if (rememberMe) {
                localStorage.setItem('remembered_email', email);
            }

            // Redirect to the home page after successful login
            window.location.href = "/";
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        // Check localStorage or sessionStorage for remembered email
        const rememberedEmail = localStorage.getItem('remembered_email') || sessionStorage.getItem('remembered_email');
        if (rememberedEmail) {
            setEmail(rememberedEmail); // Pre-fill the email field
        }
    }, []);

    return (
        <div className="login col col-12">
            <div className="form-signin position-absolute top-50 start-50 translate-middle">
                <form onSubmit={handleLogin}>
                    <input type="hidden" name="_target_path" value="/" />
                    <div className="row">
                        <div className="col-12 text-center">
                            <h3><strong className="green-text">Login</strong></h3>
                        </div>
                    </div>
                    {error && (
                        <div id="error-message" className="alert alert-danger">
                            {error}
                        </div>
                    )}
                    <div className="row">
                        <div className="col-12 form-floating">
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder=""
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <label htmlFor="email">
                                <i className="fa-regular fa-envelope"></i> Email
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 form-floating">
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder=""
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <label htmlFor="password">
                                <i className="fa-solid fa-lock"></i> Password
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <a href="/reset-password" className="green-text">Forgot Password?</a>
                            <label className="mb-0 green-text">
                                <input type="checkbox" id="remember-me" value="remember-me"/> Remember me
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button className="w-100 btn btn-lg btn-primary" type="submit">
                                Sign In
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
